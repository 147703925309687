// 引入 axios
import axios from "axios";

// let base = 'http://192.168.0.93:8890';
let base = 'http://139.9.39.57:8890';
// let base = 'http://localhost:8890';
//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}


export const postRequest=(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data: params,
    })
}
